<template>
  <div class="vx-col w-full mb-base">
    <vx-card>
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <small class="ml-1">Branch</small>
          <v-select
            v-validate="`required`"
            v-model="purchases.branch"
            :options="branchOptions"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full"
            label="branch_name"
            name="branch_name"
          />
          <span
            v-show="errors.has(`branch_name`)"
            class="text-danger text-sm"
            >{{ errors.first(`branch_name`) }}</span
          >
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <small class="ml-1">Supplier</small>
          <v-select
            v-validate="`required`"
            v-model="purchases.supplier"
            :options="supplierOptions"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full"
            label="supplier_name"
            name="supplier_name"
          />
          <span
            v-show="errors.has(`supplier_name`)"
            class="text-danger text-sm"
            >{{ errors.first(`supplier_name`) }}</span
          >
        </div>
        <div class="vx-col sm:w-1/2 mb-2">
          <small class="ml-1 w-full">Supplier Order Number</small>
          <vs-input v-model="order_id" placeholder="##" class="w-full" />
        </div>
        <div v-show="false" class="vx-col sm:w-1/3 w-full mb-2">
          <small class="ml-1 mt-10 w-full"> Purchase Delivered </small>
          <vx-tooltip
            :text="
              fulfilled
                ? 'Turning this off will not restock items and will save this purchase order with a PENDING status'
                : 'Switching this on would update the items stock values on submission hence fulfilling this purchase order'
            "
            position="left"
          >
            <div class="vx-row ml-2 mt-2">
              <i class="material-icons mr-2" style="font-size: 16px">info</i>
              <vs-switch v-model="fulfilled" />
            </div>
          </vx-tooltip>
        </div>
      </div>
      <div>
        <vs-divider>ITEMS</vs-divider>
        <div class="vx-row mt-4">
          <div class="vx-col w-full mb-2">
            <vs-table :data="purchases" hover-flat>
              <template slot="thead">
                <vs-th>Item</vs-th>
                <vs-th>Code</vs-th>
                <vs-th>Cost Price</vs-th>
                <vs-th>Retail Price</vs-th>
                <vs-th>Restock Quantity</vs-th>
                <vs-th>Amount</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template>
                <vs-tr v-for="(tr, index) in purchases.products" :key="index">
                  <vs-td>
                    <v-select
                      v-model="purchases.products[index].product"
                      :options="products"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="w-full"
                      label="product_name"
                      :appendToBody="true"
                      @input="getProductData(index)"
                    />
                  </vs-td>
                  <vs-td
                    ><v-select
                      v-model="purchases.products[index].product"
                      :options="products"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      class="w-full"
                      label="product_code"
                      :appendToBody="true"
                      @input="getProductData(index)"
                    />
                  </vs-td>
                  <vs-td
                    ><vs-input
                      v-validate="
                        `required|numeric|max_value:${purchases.products[index].retail_price}`
                      "
                      :disabled="purchases.products[index].product == null"
                      v-model="purchases.products[index].cost_price"
                      :name="`Cost-${index}`"
                      class="inputx"
                      placeholder="##"
                      @input="updatePriceR(index)"
                    />
                    <span
                      v-show="errors.has(`Cost-${index}`)"
                      class="text-danger text-sm"
                      >{{ errors.first(`Cost-${index}`) }}</span
                    >
                  </vs-td>
                  <vs-td
                    ><vs-input
                      v-validate="
                        `required|numeric|min_value:${purchases.products[index].cost_price}`
                      "
                      :disabled="purchases.products[index].product == null"
                      v-model="purchases.products[index].retail_price"
                      :name="`Retail-${index}`"
                      placeholder="##"
                      @input="updatePriceY(index)"
                    />
                    <span
                      v-show="errors.has(`Retail-${index}`)"
                      class="text-danger text-sm"
                      >{{ errors.first(`Retail-${index}`) }}</span
                    >
                  </vs-td>
                  <vs-td>
                    <vx-tooltip
                      :text="
                        'Current Available stock is ' +
                        purchases.products[index].product_stock
                      "
                      position="top"
                    >
                      <vs-input
                        v-validate="'required|min_value:1'"
                        :name="'product-' + index + '-quantity'"
                        :disabled="purchases.products[index].product == null"
                        v-model="purchases.products[index].product_quantity"
                        placeholder="##"
                        @input="updatePrice(index)"
                      />
                      <span
                        v-show="errors.has('product-' + index + '-quantity')"
                        class="text-danger text-sm"
                        >{{
                          errors.first('product-' + index + '-quantity')
                        }}</span
                      >
                    </vx-tooltip>
                  </vs-td>
                  <vs-td>
                    {{
                      Number(tr.product_total).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      })
                    }}
                  </vs-td>
                  <vs-td>
                    <feather-icon
                      v-if="purchases.products.length > 1 && index != 0"
                      icon="XCircleIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                      @click.stop="removeRow(index)"
                    />
                    <feather-icon
                      icon="CopyIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                      class="ml-1"
                      @click.stop="duplicateRow(index)"
                    />
                  </vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td />
                  <vs-td>
                    <feather-icon
                      icon="PlusIcon"
                      svg-classes="ml-3 w-8 h-8 hover:text-primary stroke-current"
                      @click.stop="addRow"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <vs-table :data="purchases" hover-flat class="w-1/4 ml-auto mt-4">
              <vs-tr>
                <th>TOTAL</th>
                <td>
                  {{
                    Number(total).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    })
                  }}
                </td>
              </vs-tr>
            </vs-table>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full mb-2">
            <vs-button
              :disabled="!isFormValid"
              class="mr-6"
              @click.stop="submitData"
              >Update Products</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import idb from '@/db/idb';
import vSelect from 'vue-select';

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      saving: false,
      initial: true,
      fulfilled: true,
      order_id: '',
      purchases: {
        products: [],
        amount: 0,
        branch: null,
        supplier: null,
      },
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    };
  },
  pouch: {
    productData() {
      return {
        database: 'express',
        selector: { type: 'product' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    supplierData() {
      return {
        database: 'express',
        selector: { type: 'supplier' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    branchData() {
      return {
        database: 'express',
        selector: { type: 'branch' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
    stockData() {
      return {
        database: 'express',
        selector: {
          type: 'stock',
          // branch_id: this.currentBranch || undefined,
        },
      };
    },
  },
  computed: {
    total() {
      var total = 0;
      for (var i = 0; i < this.purchases.products.length; i++) {
        total = total + this.purchases.products[i].product_total;
      }
      this.purchases.amount = total;
      return total;
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.purchases.supplier &&
        this.purchases.branch &&
        this.purchases.amount > 0
      );
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    branchOptions() {
      var p = this.branchData ? this.branchData : [];
      if (p.length == 1) {
        this.purchases.branch = p[0];
      }
      return p;
    },
    supplierOptions() {
      return this.supplierData ? this.supplierData : [];
    },
    all_products() {
      return this.productData ? this.productData : [];
    },
    products() {
      var productData = this.productData ? this.productData : [];
      var stockData = this.stockData ? this.stockData : [];
      var productsFilter = [];

      if (this.productData != null) {
        var productsFinal = [];
        if (this.purchases.branch != null) {
          for (var i = 0; i < this.purchases.products.length; i++) {
            var pp = this.purchases.products[i];
            if (pp == undefined || pp.product == undefined) {
              continue;
            }
            if (pp.product != null) {
              productsFinal.push(pp.product);
            }
          }

          for (let i = 0; i < productData.length; i++) {
            switch (productData[i].version) {
              case 2:
                // V2 item
                productData[i].stockv2 =
                  stockData.filter((e) => e.item_id === productData[i]._id) ||
                  [];
                break;

              default:
                break;
            }
          }

          const productsFilter1 = productData.filter(
            (el) => !productsFinal.find((e) => e._id == el._id),
          );
          // v1
          const productsFilterV1 = productsFilter1.filter((e) => {
            if (e.stock != null) {
              return Object.keys(e.stock).includes(this.purchases.branch._id);
            }
          });

          const productsFilterV2 = productsFilter1.filter((e) => {
            if (e.stockv2 != null) {
              return e.stockv2.filter(
                (e) => e.branch_id === this.purchases.branch._id,
              ).length;
            }
          });

          productsFilter = productsFilterV1;
          productsFilter = productsFilter.concat(productsFilterV2);
        }
      }
      return productsFilter;
    },
  },
  created() {
    this.addRow();
  },
  mounted() {
    this.isMounted = true;
    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false);
  },
  methods: {
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
    getProductDataOld(index) {
      if (this.purchases.products[index].product_code !== null) {
        var _id = this.purchases.products[index].product_code._id;
        var product = this.all_products.find((ppp) => ppp._id == _id);

        if (product != undefined) {
          this.purchases.products[index].product_id = product._id;
          this.purchases.products[index].product_code = product.product_code;
          this.purchases.products[index].cost_price = product.cost_price;
          this.purchases.products[index].retail_price = product.retail_price;
          this.purchases.products[index].product_total =
            product.cost_price *
            this.purchases.products[index].product_quantity;
          this.purchases.products[index].product_rtotal =
            product.cost_price *
            this.purchases.products[index].product_quantity;
          this.initial = false;
        }
      } else if (this.purchases.products[index].product !== null) {
        var _id2 = this.purchases.products[index].product._id;
        var product2 = this.all_products.find((ppp) => ppp._id == _id2);

        if (product2) {
          this.purchases.products[index].product_id = product2._id;
          this.purchases.products[index].product_code = product2.product_code;
          this.purchases.products[index].cost_price = product2.cost_price;
          this.purchases.products[index].retail_price = product2.retail_price;
          this.purchases.products[index].product_total =
            product2.cost_price *
            this.purchases.products[index].product_quantity;
          this.purchases.products[index].product_rtotal =
            product2.cost_price *
            this.purchases.products[index].product_quantity;
          this.initial = false;
        }
      } else {
        this.initial = true;
        const obj = {
          product_id: 0,
          product: null,
          product_code: null,
          cost_price: 0,
          retail_price: 0,
          product_quantity: 1,
          product_total: 0,
          product_rtotal: 0,
        };
        this.purchases.products[index] = obj;
      }
    },
    getRowObj() {
      const obj = {
        product_id: 0,
        product: null,
        product_code: null,
        cost_price: null,
        retail_price: null,
        product_quantity: 1,
        product_total: 0,
        product_rtotal: 0,
      };
      return obj;
    },
    getProductData(index) {
      var row = this.purchases.products[index];

      if (row.product != undefined || row.product != null) {
        var product = this.all_products.find((e) => e._id == row.product._id);
        if (product) {
          switch (product.version) {
            case 2:
              row.product_stockv2 = product.stockv2
                ? product.stockv2.find(
                    (e) => e.branch_id == this.purchases.branch._id,
                  )
                : [];
              row.product_stock = row.product_stockv2
                ? row.product_stockv2.stock
                : 0;
              break;

            default:
              row.product_stock = product.stock[this.purchases.branch._id];
              break;
          }

          row.product_quantity = 1;
          row.product_version = product.version;

          row.product_id = product.id;
          row.product_code = product.product_code;

          row.retail_price = product.retail_price;
          row.cost_price = product.cost_price;

          row.product_total = product.cost_price * row.product_quantity;
          row.product_rtotal = product.cost_price * row.product_quantity;

          this.purchases.products[index] = row;
          return;
        }
      }
      this.purchases.products.splice(index, 1);
      this.purchases.products.push(this.getRowObj());
    },
    updatePriceR(index) {
      if (
        this.purchases.products[index].product_quantity > 0 &&
        this.purchases.products[index].cost_price > 0
      ) {
        this.purchases.products[index].product_total =
          this.purchases.products[index].product_quantity *
          this.purchases.products[index].cost_price;
        this.purchases.products[index].product_rtotal =
          this.purchases.products[index].product_quantity *
          this.purchases.products[index].retail_price;
      }
    },
    updatePriceY(index) {
      // if(this.purchases.products[index].retail_price > 0) {
      //     this.purchases.products[index].retail_price = this.purchases.products[index].product_quantity * this.purchases.products[index].retail_price
      // }
    },
    updatePrice(index) {
      if (
        this.purchases.products[index].product_quantity > 0 &&
        this.purchases.products[index].cost_price > 0
      ) {
        this.purchases.products[index].product_total =
          this.purchases.products[index].product_quantity *
          this.purchases.products[index].cost_price;
        this.purchases.products[index].product_rtotal =
          this.purchases.products[index].product_quantity *
          this.purchases.products[index].retail_price;
      }
    },
    duplicateRow(index) {
      this.addRow();
      var last = this.purchases.products.slice(-1).pop();
      this.purchases.products[this.purchases.products.length - 1] =
        this.purchases.products[index];
    },
    addRow() {
      const obj = {
        product_id: 0,
        product: null,
        product_code: null,
        cost_price: null,
        retail_price: null,
        product_quantity: 1,
        product_total: 0,
        product_rtotal: 0,
      };
      this.purchases.products.push(obj);
    },
    removeRow(id) {
      this.purchases.products.splice(id, 1);
    },
    showMessage(icon, color, title, message) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: icon,
        color: color,
      });
    },
    submitData() {
      try {
        if (this.isFormValid) {
          const obj = {
            amount: this.total,
            order_id: this.order_id,
            products: this.purchases.products,
            fulfilled: this.fulfilled,
            branch: this.purchases.branch._id,
            supplier: this.purchases.supplier._id,
            products_raw: [],
          };
          var products_raw = [];

          for (var i = 0; i < obj.products.length; i++) {
            var p = obj.products[i];
            if (p.product_id != 0 && p.product != undefined) {
              if (p.product.version == 2 && p.product.stockv2) {
                var currentStock = (this.stockData || []).find(
                  (e) =>
                    e.item_id == p.product._id &&
                    e.branch_id == this.purchases.branch._id,
                );

                if (!currentStock) {
                  // Current Stock is null, skip this
                  throw new Error(
                    `Missing stock data for item '${p.product.product_name}''`,
                  );
                }

                var newStock =
                  (typeof currentStock.stock === 'number'
                    ? currentStock.stock
                    : 0) + parseInt(p.product_quantity);

                if (newStock == null || typeof newStock !== 'number') {
                  // TODO: throw error
                  throw new Error(
                    `Missing stock data for item '${p.product.product_name}''`,
                  );
                }
                if (newStock < 0) {
                  // TODO: throw error
                  this.showMessage(
                    'icon-check-circle',
                    'warning',
                    'Insufficient Stock',
                    `Unable to complete purchase order due to insufficient stock for item '${p.product.product_name}''`,
                  );
                  continue;
                } else {
                  // Create new update stock item record
                  currentStock.stock = newStock;
                  console.debug('currentStock', currentStock);
                  // Reject sale if new stock is null
                  if (!Number.isInteger(currentStock.stock)) {
                    throw new Error('Invalid stock entry');
                  }
                  if (this.fulfilled) {
                    this.$store
                      .dispatch('products/updateProductStock', currentStock)
                      .then((response) => {
                        this.showMessage(
                          'icon-check-circle',
                          'success',
                          'Stock Update Successfully',
                          `${response.id}`,
                        );
                      });
                  }

                  p.product.newStock = {};
                  p.product.newStock[this.purchases.branch._id] = parseInt(
                    p.product_quantity,
                  );

                  products_raw.push({
                    ...p.product,

                    date_updated: Date.now(),
                  });

                  obj.version = 2;
                }
              } else {
                p.product = {
                  ...p.product,
                  previousStock: {
                    ...p.product.previousStock,
                  },
                  newStock: {
                    ...p.product.newStock,
                  },
                };

                p.product.stock[this.purchases.branch._id] =
                  parseInt(p.product.stock[this.purchases.branch._id]) +
                  parseInt(p.product_quantity);
                p.product.previousStock[this.purchases.branch._id] = parseInt(
                  p.product.stock[this.purchases.branch._id],
                );
                p.product.newStock[this.purchases.branch._id] = parseInt(
                  p.product_quantity,
                );
                products_raw.push({
                  ...p.product,
                  date_updated: Date.now(),
                });
              }
            }
          }
          obj.products_raw = products_raw;
          this.$vs.loading();
          this.$store
            .dispatch('purchases/addPurchase', obj)
            .then((response) => {
              this.showMessage(
                'icon-check-circle',
                'success',
                'Purchase Recorded Successfully',
                'You have successfully recorded a new purchase',
              );

              if (this.fulfilled) {
                for (var i = 0; i < obj.products_raw.length; i++) {
                  var p = obj.products_raw[i];
                  if (p.product_id != 0 && p.product) {
                    let previous_quantity = 0;
                    switch (p.product.version) {
                      case 2:
                        var currentStock = (this.stockData || []).find(
                          (e) =>
                            e.item_id == p.product._id &&
                            e.branch_id == this.purchases.branch._id,
                        );

                        if (!currentStock) {
                          // Current Stock is null, skip this, we notified above
                          continue;
                        }

                        previous_quantity =
                          parseInt(currentStock.stock) +
                          parseInt(p.product_quantity);

                        break;

                      default:
                        previous_quantity =
                          parseInt(p.product.stock[this.purchases.branch._id]) +
                          parseInt(p.product_quantity);
                        break;
                    }

                    this.$store.dispatch('products/addProductStockUpdate', {
                      product_id: p.product_id,
                      branch_id: this.purchases.branch._id,
                      purchase_order_id: response.id,
                      quantity: p.product_quantity,
                      previous_quantity: previous_quantity,
                      action: 'add',
                      caller: 'new_purchase',
                    });
                  }
                }
              }

              this.$vs.loading.close();
              this.$router
                .push(`/store/purchases/${response.id}/receipt`)
                .catch(() => {});
            })
            .catch((err) => {
              this.$vs.loading.close();
              console.error(err);
              this.showMessage('icon-error-circle', 'danger', 'Error', err);
            });
        }
      } catch (error) {
        this.showMessage(
          'icon-check-circle',
          'danger',
          'Something went wrong',
          `Error: ${error.message}`,
        );
      }
    },
    getStatusColor(status) {
      if (status == true) return 'success';
      return 'danger';
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    deleteData(id) {
      // this.$store.dispatch("jobs/removeJob", id).catch(err => { console.error(err) })
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .name {
      max-width: 18rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
